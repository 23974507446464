import {writable} from "svelte/store";


export const default_time_per_panel = writable(9.1)
export const default_time_per_mistake = writable(1.3)
export const default_persistence = writable(false)
export const has_opened_example = writable(false)
export const has_clicked_node = writable(false)

// fix redo- last butto nstays on

export const is_loaded = writable(false)