<script>
  import {onMount} from "svelte";
  import {building} from "$app/environment";
 // import AtomicMistake from "$lib/atomic_mistake.svelte";
  import {default_time_per_panel, default_time_per_mistake} from "$lib/store.js";
  let pkg
  export let equation;
  export let mistakes;
  export let show_points=false;
  export let is_student=false
  export let sequential_mistake_display=false
  //export let edit_sequence;
  let equation_div;
  let mistake_div;
  let timeout
  let is_set=false
  export let wait_time;
  let s_delay_per_mistake = 0.8125
  function make_equation(eq_div){
    if(!eq_div || !pkg || is_set)
      return
    if(equation instanceof String)
      equation = JSON.parse(equation);
    is_set=true
    console.log("Have transmittable", pkg.TransmittableMessage)
    console.log("With equation", equation)
    let tm = new pkg.TransmittableMessage().deserialize(equation)
    console.log("About to create transmittable")
    if(is_student){
      pkg.edit_default_display_property('is_student_mode',true,'mistakes')
    }
    pkg.make_default_uneditable()
    pkg.make_default_editable('mistakes')

    if(show_points){
      pkg.make_points_allocatable()
    }else
      pkg.remove_points_allocatable()
    if(tm.hasOwnProperty('total_transition_seconds')){
      wait_time = tm.total_transition_seconds()
    }else{
      console.log("transmittable does not have total transition seconds", tm)
      wait_time = $default_time_per_panel
    }
    console.log('HAVE WAIT TIME')
    if(mistakes)
      wait_time += mistakes.length * $default_time_per_mistake
    tm.display(eq_div, ()=>{}, pkg.make_default_display_properties())
    // FUCK THOSE BITCHES
    // if(edit_sequence){
    //   console.log('fuck your cunt of a mother fucking bullshit package mathlive', )
    //   //timeout = pkg.execute_edits(edit_sequence)
    // }
    /* todo: add edit time configurability and ensure alignment
    *
    * */
    if(mistakes){
      mistakes.forEach((m,i)=>{

        if(sequential_mistake_display) {
          setTimeout(
              () => {
                let rmist = new pkg.Mistake().deserialize(m)
                rmist.display(equation_div,mistake_div)
              }, s_delay_per_mistake //$default_time_per_mistake
              * (i) * 1000
          )
        }else{
          let rmist = new pkg.Mistake().deserialize(m)
          rmist.display(equation_div,mistake_div)
        }
      })
    }
  }

  //$: make_equation(equation_div)
  onMount(
    ()=>{
      //if(building)
      //  return
      console.log('Have a window', window)
      pkg = window.transmittable_message
      console.log("About to make equation")
      make_equation(equation_div)
      console.log("HAVE WAIT TIME", wait_time)
    }
  )
</script>
<style>
  .outer-mistakes{
    display: flex;
    flex-wrap: wrap;
  }
</style>
<div>
  <div bind:this={equation_div}>
  </div>
  <div class="outer-mistakes" bind:this={mistake_div}>
  </div>
</div>